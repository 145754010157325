import { Container, Typography } from "@mui/material";

import { SocialMediaControls } from "@freshbuffer/modules/SocialMediaShare/SocialMediaControls";
import { CommonStyles } from "@freshbuffer/styles/CommonStyles";
import classNames from "classnames";
import styles from "./Footer.module.css";


export const Footer = () => {

  return (
    <footer id="page-footer" data-testid="PageFooter" className={styles.root}>
      <Container>
      
        <div className={styles.socialNetworkLinks}>
          <SocialMediaControls emailBody="Connect with Freshbuffer Team" emailSubject="Connect with Freshbuffer Team" />
          
        </div>

        <div className={styles.divider} />
       <div className={styles.addressContainer}>
        <div>
        <Typography variant="h4">{'INDIA'}</Typography>
          <Typography variant="body2" className={styles.cardText}>
            {'Anna bhavan'}
          </Typography>
          <Typography variant="body2" className={styles.cardText}>
            {'Elappara - 625301'}
          </Typography>
          <Typography variant="body2" className={styles.cardText}>
            {'Idukki, Kerala'}
          </Typography>
          <Typography variant="body2" className={styles.cardText}>
            {'+91 8675407049'}
          </Typography>
        </div>
        <div>
        <Typography variant="h4">{'NETHERLANDS'}</Typography>
          <Typography variant="body2" className={styles.cardText}>
            {'Raaltepad 5'}
          </Typography>
          <Typography variant="body2" className={styles.cardText}>
            {'Almere'}
          </Typography>
          <Typography variant="body2" className={styles.cardText}>
            {'1324 ET'}
          </Typography>
          <Typography variant="body2" className={styles.cardText}>
            {'+31 647120144'}
          </Typography>
        </div>
      </div>
        <div className={classNames(CommonStyles.margin_top_4)}>
        <Typography variant="caption" >
        (C) 2024 Freshbuffer. All rights reserved.
        </Typography>
        </div>
    
      </Container>
    </footer>
  );
};
